
import DataItem from "./components/DataItem.vue";
// import OrderChart from "./components/chart/OrderChart.vue";
import SalesChart from "./components/chart/SalesChart.vue";
import StudentChart from "./components/chart/StudentChart.vue";
import EnrollmentChannelsChart from "./components/chart/EnrollmentChannelsChart.vue";
import FullYearSalesChart from "./components/chart/FullYearSalesChart.vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import { useLayoutStore } from "@/layouts/hooks";
export default defineComponent({
  name: "Home",
  components: {
    DataItem,
    // OrderChart,
    SalesChart,
    StudentChart,
    EnrollmentChannelsChart,
    FullYearSalesChart,
  },
  setup() {
    const layoutStore = useLayoutStore();
    // const mOrderChart = ref<InstanceType<typeof OrderChart>>();
    const salesChart = ref<InstanceType<typeof SalesChart>>();
    const enrollmentChannelsChart =
      ref<InstanceType<typeof EnrollmentChannelsChart>>();
    const studentChart = ref<InstanceType<typeof StudentChart>>();
    const fullYearSalesChart = ref<InstanceType<typeof FullYearSalesChart>>();
    const onResize = () => {
      setTimeout(() => {
        // mOrderChart.value?.updateChart();
        salesChart.value?.updateChart();
        enrollmentChannelsChart.value?.updateChart();
        studentChart.value?.updateChart();
        fullYearSalesChart.value?.updateChart();
      }, 500);
    };
    const collapse = computed(() => {
      return layoutStore.state.isCollapse;
    });
    watch(collapse, (newVal: boolean) => {
      onResize();
    });
    return {
      collapse,
      // mOrderChart,
      salesChart,
      enrollmentChannelsChart,
      studentChart,
      fullYearSalesChart,
      dataList: [
        {
          title: "今日浏览量",
          data: "+1000",
          extra: {
            data: 1000,
            data1: 2350,
          },
        },
        {
          title: "今日新增用户",
          data: "+500",
          bottomTitle: "总用户量",
          totalSum: "200万+",
          extra: {
            data: 700,
            data1: 968,
          },
        },
        {
          title: "今日访问量",
          data: "+1000",
          bottomTitle: "总访问量",
          totalSum: "100万+",
          extra: {
            data: 1000,
            data1: 2350,
          },
        },
        {
          title: "今日销量额",
          data: "+500",
          bottomTitle: "总用户量",
          totalSum: "200万+",
          extra: {
            data: 700,
            data1: 968,
          },
        },

      ],
    };
  },
});
