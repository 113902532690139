
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DataItem",
  props: {
    dataModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const loading = ref(true);
    setTimeout(() => {
      loading.value = false;
    }, 1000);
    return {
      loading,
    };
  },
});
